var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "h4",
                  { staticClass: "mb-1" },
                  [
                    _c("feather-icon", {
                      staticClass: "mr-50",
                      attrs: { icon: "CameraIcon", size: "22" }
                    }),
                    _vm._v(
                      " Linking / Embedding -- " +
                        _vm._s(_vm.cameraData.sitename) +
                        ", " +
                        _vm._s(_vm.cameraData.location_name) +
                        " "
                    )
                  ],
                  1
                ),
                _c("small", [
                  _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
                ]),
                _c("br")
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 mb-1 border-bottom-primary" },
            [_c("b-col", [_c("h4", [_vm._v("Image Linking and Embedding")])])],
            1
          ),
          _vm.$can("update", "cameras") ||
          _vm.$can("update", "camerasmanagement")
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "is_demo",
                            name: "is_demo",
                            "input-id": "size_selection",
                            "return-object": ""
                          },
                          on: { input: _vm.updateCameraDemoSetting },
                          model: {
                            value: _vm.cameraData.is_demo,
                            callback: function($$v) {
                              _vm.$set(_vm.cameraData, "is_demo", $$v)
                            },
                            expression: "cameraData.is_demo"
                          }
                        },
                        [_vm._v(" Enable Linking / Embedding ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.$can("update", "cameras") ||
            _vm.$can("update", "camerasmanagement")) &&
          _vm.cameraData.is_demo &&
          _vm.cameraUUID !== null
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Select Size to Display Image",
                            "label-for": "size_selection"
                          }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "size_selection",
                              options: _vm.sizeOptions,
                              reduce: function(val) {
                                return val.value
                              },
                              clearable: false,
                              "input-id": "size_selection",
                              "return-object": ""
                            },
                            on: { input: _vm.onSizeSelect },
                            model: {
                              value: _vm.selectedResolution,
                              callback: function($$v) {
                                _vm.selectedResolution = $$v
                              },
                              expression: "selectedResolution"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.$can("update", "cameras") ||
            _vm.$can("update", "camerasmanagement")) &&
          _vm.cameraData.is_demo &&
          _vm.cameraUUID !== null &&
          _vm.selectedResolution !== null
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Embed HTML Content",
                            "label-for": "embed_content"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            ref: "embedTA",
                            attrs: {
                              id: "embed_content",
                              value: _vm.embedContent,
                              rows: "3",
                              "max-rows": "6"
                            },
                            on: {
                              focus: function($event) {
                                return $event.target.select()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "pl-4 pr-4",
                          attrs: { variant: "primary" },
                          on: { click: _vm.copyToClipboard }
                        },
                        [_vm._v(" Copy ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.$can("update", "cameras") ||
            _vm.$can("update", "camerasmanagement")) &&
          _vm.cameraData.is_demo &&
          _vm.cameraUUID !== null &&
          _vm.selectedResolution !== null
            ? _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "pl-4 pr-4",
                          attrs: { id: "previewBtn", variant: "primary" },
                          on: { click: _vm.previewiFrame }
                        },
                        [_vm._v(" Preview ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "vmodal",
          attrs: {
            id: "vmodal",
            "ok-only": "",
            "ok-title": "Close",
            centered: "",
            size: "xl",
            title: _vm.previewTitle
          },
          on: {
            close: function($event) {
              _vm.showIframeModal = false
            }
          }
        },
        [_c("p", { domProps: { innerHTML: _vm._s(_vm.embedContent) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }