var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.showLoadingOverlay,
            rounded: "sm",
            variant: "transparent",
            blur: "5px"
          }
        },
        [
          _c("filmstrip-sidebar", {
            attrs: {
              "is-filmstrip-sidebar-active": _vm.isFilmstripSidebarActive,
              "camera-id-prop": _vm.cameraData.unit_number,
              "camera-data": _vm.cameraData,
              "camera-media": _vm.cameraMedia,
              "current-image-date-time": _vm.currentImageDateTime
            },
            on: {
              "update:isFilmstripSidebarActive": function($event) {
                _vm.isFilmstripSidebarActive = $event
              },
              "update:is-filmstrip-sidebar-active": function($event) {
                _vm.isFilmstripSidebarActive = $event
              },
              "update:cameraIdProp": function($event) {
                return _vm.$set(_vm.cameraData, "unit_number", $event)
              },
              "update:camera-id-prop": function($event) {
                return _vm.$set(_vm.cameraData, "unit_number", $event)
              },
              "update:cameraData": function($event) {
                _vm.cameraData = $event
              },
              "update:camera-data": function($event) {
                _vm.cameraData = $event
              },
              "update:cameraMedia": function($event) {
                _vm.cameraMedia = $event
              },
              "update:camera-media": function($event) {
                _vm.cameraMedia = $event
              },
              "update:currentImageDateTime": function($event) {
                _vm.currentImageDateTime = $event
              },
              "update:current-image-date-time": function($event) {
                _vm.currentImageDateTime = $event
              },
              itemclicked: _vm.handleFilmstripClick,
              getcameramedia: _vm.handleFilmstripGetCameraMedia,
              setcurrentimage: _vm.handleFilmstripSetCurrentImage,
              setcurrentdatetime: _vm.handleFileStripSetCurrentDateTime
            }
          }),
          _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "mb-0" },
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-md-8" },
                    [
                      _c(
                        "h4",
                        { staticClass: "mb-1" },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "CameraIcon", size: "22" }
                          }),
                          _vm._v(
                            " Images & Videos -- " +
                              _vm._s(_vm.cameraData.sitename) +
                              ", " +
                              _vm._s(_vm.cameraData.location_name)
                          ),
                          _c("br")
                        ],
                        1
                      ),
                      _c("small", [
                        _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
                      ]),
                      !_vm.cameraData.is_active
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize ml-1",
                              attrs: { pill: "", variant: "light-danger" }
                            },
                            [_vm._v(" Disabled ")]
                          )
                        : _vm._e(),
                      !_vm.cameraData.is_online
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize ml-1",
                              attrs: { pill: "", variant: "light-warning" }
                            },
                            [_vm._v(" Offline ")]
                          )
                        : _vm._e(),
                      _c("br"),
                      _c("small", [
                        _vm._v("Time Zone: " + _vm._s(_vm.cameraData.time_zone))
                      ]),
                      _c("br")
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-md-4 text-right" },
                    [
                      (_vm.$can("update", "cameras") ||
                        _vm.$can("update", "camerasmanagement")) &&
                      _vm.cameraData.is_active &&
                      _vm.cameraData.is_online
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                id: "button-3",
                                variant: "primary",
                                type: "button"
                              },
                              on: { click: _vm.takePicture }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CameraIcon" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-tooltip", {
                        attrs: {
                          target: "button-3",
                          title: "Take Picture",
                          placement: "bottom"
                        }
                      }),
                      _vm.$can("read", "cameras") ||
                      _vm.$can("read", "camerasmanagement")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                id: "button-2",
                                variant: "primary",
                                type: "button",
                                disabled: _vm.disableDownload
                              },
                              on: { click: _vm.downloadImage }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "DownloadIcon" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-tooltip", {
                        attrs: {
                          target: "button-2",
                          title: "Download",
                          placement: "bottom"
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: "sidebarBtn",
                            variant: "primary",
                            type: "button"
                          },
                          on: {
                            click: function($event) {
                              _vm.isFilmstripSidebarActive = true
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { icon: "SidebarIcon" } })
                        ],
                        1
                      ),
                      _c("b-tooltip", {
                        attrs: {
                          target: "sidebarBtn",
                          title: "Open Filmstrip",
                          placement: "bottom"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isVideoDownloaded
                ? _c(
                    "b-row",
                    { staticClass: "mt-1 mt-md-0 mb-0" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                id: "playVideoBtn",
                                variant: "primary",
                                type: "button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.playVideoFile(_vm.currentVideoItem)
                                }
                              }
                            },
                            [_vm._v(" Play Video ")]
                          ),
                          _c("b-tooltip", {
                            attrs: {
                              target: "playVideoBtn",
                              title: "Play Motion Video",
                              placement: "bottom"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.hadMotionDetected && _vm.cameraData.is_online
                ? _c(
                    "b-row",
                    { staticClass: "mt-0 mb-0" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-1 mt-md-0 text-right",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                id: "acknowledgeBtn",
                                variant: "primary",
                                type: "button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.playVideoFile(_vm.currentVideoItem)
                                }
                              }
                            },
                            [_vm._v(" Download Video ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mb-0 mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _vm.hadMotionDetected &&
                      _vm.currentImageDateTime !== undefined
                        ? _c("h3", [
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("Motion Alert: ")
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.formatDateTimeAMPM(
                                  _vm.currentImageDateTime.replace("T", " ")
                                )
                              ) + " "
                            )
                          ])
                        : _c("h3", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatDateTimeAMPM(
                                    _vm.currentImageDateTime.replace("T", " ")
                                  )
                                ) +
                                " "
                            )
                          ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-media",
                { staticClass: "mb-1" },
                [
                  _vm.hadMotionDetected && _vm.theImage !== ""
                    ? _c("lazy-image-component", {
                        staticClass: "rounded border-danger",
                        attrs: {
                          fluid: "",
                          src: _vm.theImage,
                          "src-placeholder": "@/assets/images/noImage.png"
                        }
                      })
                    : _vm.cameraData.latest_image !== undefined &&
                      _vm.cameraData.latest_image.access_url !== "" &&
                      _vm.theImage !== ""
                    ? _c("lazy-image-component", {
                        staticClass: "rounded",
                        attrs: {
                          alt:
                            _vm.cameraData.sitename +
                            "-" +
                            _vm.cameraData.location_name,
                          fluid: "",
                          src: _vm.theImage,
                          "src-placeholder": "@/assets/images/noImage.png"
                        }
                      })
                    : _c("b-img", {
                        staticClass: "rounded",
                        attrs: {
                          alt:
                            _vm.cameraData.unit_number +
                            "-" +
                            _vm.cameraData.camera_id,
                          fluid: "",
                          src: require("@/assets/images/noImageLg.png")
                        }
                      })
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-0 mb-1 ml-0 mr-0" },
                [
                  _c(
                    "b-col",
                    { staticClass: "col-3 col-xl-3 ml-0 mr-0" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "pl-2 pr-2 pl-xl-4 pr-xl-4",
                          attrs: {
                            id: "prevBtn",
                            variant: "primary",
                            disabled: _vm.disablePreviousButton
                          },
                          on: { click: _vm.showPreviousImage }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "ArrowLeftIcon" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-none d-md-block col-md-3 text-right ml-0 mr-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            attrs: {
                              config: {
                                enableTime: true,
                                dateFormat: "Y-m-d H:i",
                                altInput: true,
                                altFormat: "F j, Y H:i",
                                maxDate: _vm.maxDateTime,
                                allowInput: true
                              }
                            },
                            on: {
                              "on-close": _vm.onCloseImageDateTime,
                              "on-open": function($event) {
                                _vm.showcheckBtn = true
                              }
                            },
                            model: {
                              value: _vm.currentDateTime,
                              callback: function($$v) {
                                _vm.currentDateTime = $$v
                              },
                              expression: "currentDateTime"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "input-group-btn" },
                            [
                              _vm.showcheckBtn
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "pl-1 pr-1",
                                      attrs: { variant: "primary" }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "CheckIcon" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-2 col-md-1" },
                    [
                      _vm.$can("read", "cameras") ||
                      _vm.$can("read", "camerasmanagement")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                id: "button-cal",
                                variant: "primary",
                                type: "button"
                              },
                              on: { click: _vm.openCalendar }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CalendarIcon" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-tooltip", {
                        attrs: {
                          target: "button-cal",
                          title: "Open Media Calendar",
                          placement: "bottom"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-4 col-md-2" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { id: "latestBtn", variant: "primary" },
                          on: { click: _vm.showLatestImage }
                        },
                        [_vm._v(" Latest ")]
                      ),
                      _c("b-tooltip", {
                        attrs: {
                          target: "latestBtn",
                          title: "Show Latest Image",
                          placement: "bottom"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-2 col-xl-3 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "pl-2 pr-2 pl-xl-4 pr-xl-4",
                          attrs: {
                            id: "nextBtn",
                            variant: "primary",
                            disabled: _vm.disableNextButton
                          },
                          on: { click: _vm.showNextImage }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "ArrowRightIcon" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3 mb-1 border-bottom-primary" },
                [
                  _c("b-col", [
                    _c("h4", [
                      _vm._v(
                        "Videos -- " + _vm._s(_vm.displayCurrentImageDateTime)
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "ecommerce-application",
              staticStyle: { height: "inherit" }
            },
            [
              _vm.videoItems !== null && _vm.videoItems.length
                ? _c(
                    "b-row",
                    { staticClass: "mt-2 mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-push-12 col-md-4 d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { md: "4" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.filters.perPage,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "perPage", $$v)
                              },
                              expression: "filters.perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")])
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-push-12 col-md-4 d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { md: "4" }
                        },
                        [
                          _c("label", [_vm._v("From ")]),
                          _c("b-form-timepicker", {
                            staticClass: "ml-1",
                            attrs: {
                              locale: "en",
                              placeholder: "time",
                              "reset-button": ""
                            },
                            model: {
                              value: _vm.filters.startTime,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "startTime", $$v)
                              },
                              expression: "filters.startTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-push-12 col-md-4 d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { md: "4" }
                        },
                        [
                          _c("label", [_vm._v("To ")]),
                          _c("b-form-timepicker", {
                            staticClass: "ml-1",
                            attrs: {
                              min: "timevalue",
                              locale: "en",
                              placeholder: "time",
                              "reset-button": ""
                            },
                            model: {
                              value: _vm.filters.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "endTime", $$v)
                              },
                              expression: "filters.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.videoItems !== null && _vm.videoItems.length
                ? _c(
                    "section",
                    { staticClass: "grid-view" },
                    _vm._l(_vm.videoItems, function(videoItem) {
                      return _c(
                        "b-card",
                        {
                          key: videoItem.time_camera
                            .replace("T", "")
                            .replace(" ", "")
                            .replaceAll("-", "")
                            .replaceAll(":", ""),
                          attrs: {
                            id: videoItem.time_camera
                              .replace("T", "")
                              .replace(" ", "")
                              .replaceAll("-", "")
                              .replaceAll(":", ""),
                            "no-body": "",
                            role: "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.playVideoFile(videoItem)
                            }
                          }
                        },
                        [
                          _c(
                            "b-media",
                            { attrs: { "vertical-align": "center" } },
                            [
                              _c(
                                "b-overlay",
                                {
                                  attrs: {
                                    show: videoItem.showLoadingOverlay,
                                    rounded: "sm",
                                    variant: "transparent",
                                    blur: "5px"
                                  }
                                },
                                [
                                  _c("thumbs-lazy-image-component", {
                                    staticClass: "card-img-top",
                                    attrs: {
                                      src: videoItem.access_url,
                                      "src-placeholder":
                                        "@/assets/images/noImage.png"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-card-footer",
                            { staticClass: "pt-1 pb-1 border-dark" },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Recorded On: ")
                              ]),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    videoItem.time_camera.replace("T", " ")
                                  )
                                )
                              ]),
                              _c("br"),
                              videoItem.isDownloading
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            pill: "",
                                            variant: "light-warning"
                                          }
                                        },
                                        [_vm._v(" Downloading ")]
                                      )
                                    ],
                                    1
                                  )
                                : (typeof videoItem.video_access_url ===
                                    undefined ||
                                    videoItem.video_access_url === null ||
                                    videoItem.video_access_url === "") &&
                                  _vm.cameraData.is_online
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            pill: "",
                                            variant: "light-danger"
                                          }
                                        },
                                        [_vm._v(" Click To Download ")]
                                      )
                                    ],
                                    1
                                  )
                                : !_vm.cameraData.is_online
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            pill: "",
                                            variant: "light-warning"
                                          }
                                        },
                                        [_vm._v(" Offline ")]
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            pill: "",
                                            variant: "light-success"
                                          }
                                        },
                                        [_vm._v(" Downloaded ")]
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "mt-5 mb-5 text-center",
                      staticStyle: { height: "inherit" }
                    },
                    [
                      _c("h4", [
                        _c("br"),
                        _c("br"),
                        _vm._v(" No Videos Found "),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br")
                      ])
                    ]
                  ),
              _vm.videoItems !== null && _vm.videoItems.length
                ? _c(
                    "section",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.totalProducts,
                                  "per-page": _vm.filters.perPage,
                                  "first-number": "",
                                  align: "center",
                                  "last-number": "",
                                  "prev-class": "prev-item",
                                  "next-class": "next-item"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prev-text",
                                      fn: function() {
                                        return [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronLeftIcon",
                                              size: "18"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "next-text",
                                      fn: function() {
                                        return [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronRightIcon",
                                              size: "18"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1308952388
                                ),
                                model: {
                                  value: _vm.filters.page,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "page", $$v)
                                  },
                                  expression: "filters.page"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "vmodal",
          attrs: {
            id: "vmodal",
            "ok-only": "",
            "ok-title": "Close",
            centered: "",
            size: "lg",
            title: _vm.videoTitle
          },
          on: {
            close: function($event) {
              _vm.showVideoModal = false
            }
          }
        },
        [
          _c("vue-plyr", { attrs: { options: _vm.options } }, [
            _c(
              "video",
              {
                attrs: {
                  controls: "",
                  crossorigin: "",
                  playsinline: "",
                  "data-poster": _vm.videoPoster
                }
              },
              [
                _c("source", {
                  attrs: { size: "1080", src: _vm.videoSrc, type: "video/mp4" }
                })
              ]
            )
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-1",
              attrs: {
                id: "button-video-download",
                variant: "primary",
                type: "button"
              },
              on: { click: _vm.downloadVideo }
            },
            [
              _c("feather-icon", { attrs: { icon: "DownloadIcon" } }),
              _vm._v(" Download Video File ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "tpmodal",
          attrs: {
            id: "tpmodal",
            "ok-title": "Show Image",
            "cancel-variant": "outline-secondary",
            "cancel-title": "Close",
            centered: "",
            size: "lg",
            title: "New Content Available"
          },
          on: {
            close: function($event) {
              _vm.showTPModal = false
            }
          }
        },
        [
          _vm._v(
            " A new image is available. Do you wish to view the image now? "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }