var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-row",
          { staticClass: "mb-1" },
          [
            _c("b-col", { staticClass: "col-12 col-md-8" }, [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "CameraIcon", size: "22" }
                  }),
                  _vm._v(
                    " Pan / Tilt / Zoom -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name) +
                      " "
                  )
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              _c("br")
            ]),
            _c(
              "b-col",
              { staticClass: "col-12 col-md-4 text-right" },
              [
                _vm.$can("update", "cameras") ||
                _vm.$can("update", "camerasmanagement")
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          id: "btnTakePic",
                          variant: "primary",
                          type: "button"
                        },
                        on: { click: _vm.takePicture }
                      },
                      [_c("feather-icon", { attrs: { icon: "CameraIcon" } })],
                      1
                    )
                  : _vm._e(),
                _c("b-tooltip", {
                  attrs: {
                    target: "btnTakePic",
                    title: "Take Picture",
                    placement: "bottom"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "col-12 col-lg-9" },
                  [
                    _c(
                      "b-media",
                      {
                        staticClass: "mb-1",
                        attrs: { "vertical-align": "center" }
                      },
                      [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.showLoadingOverlay,
                              rounded: "sm",
                              variant: "transparent",
                              blur: "5px"
                            }
                          },
                          [
                            _vm.theImage !== ""
                              ? _c("b-img", {
                                  staticClass: "rounded img-fluid w-100",
                                  attrs: { fluid: "", src: _vm.theImage }
                                })
                              : _c("b-img", {
                                  staticClass: "rounded img-fluid w-100",
                                  attrs: {
                                    fluid: "",
                                    src: require("@/assets/images/noImageLg.png")
                                  }
                                })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { staticClass: "col-12 col-lg-3" },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-1", attrs: { "align-v": "center" } },
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-center mb-1" },
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-h": "center" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      id: "btnUp",
                                      variant: "primary",
                                      type: "button",
                                      disabled: _vm.showLoadingOverlay
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.moveCamera("up")
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              {
                                staticClass: "mt-1 mb-1",
                                attrs: { "align-h": "center" }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "pl-2 pr-2 mr-1",
                                    attrs: {
                                      id: "btnLeft",
                                      variant: "primary",
                                      type: "button",
                                      disabled: _vm.showLoadingOverlay
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.moveCamera("left")
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowLeftIcon" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "pl-2 pr-2",
                                    attrs: {
                                      id: "btnRight",
                                      variant: "primary",
                                      type: "button",
                                      disabled: _vm.showLoadingOverlay
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.moveCamera("right")
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowRightIcon" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { attrs: { "align-h": "center" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      id: "btnDown",
                                      variant: "primary",
                                      type: "button",
                                      disabled: _vm.showLoadingOverlay
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.moveCamera("down")
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowDownIcon" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            (_vm.cameraData.device_path === "192.168.1.108" ||
                              _vm.cameraData.device_path === "192.168.1.110") &&
                            _vm.cameraData.camera_type === "Predator"
                              ? _c(
                                  "b-row",
                                  {
                                    staticClass: "mt-3 mb-1",
                                    attrs: { "align-h": "center" }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          id: "btnZIn",
                                          variant: "primary",
                                          type: "button",
                                          disabled: _vm.showLoadingOverlay
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.moveCamera("zoom_in")
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "ZoomInIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("b-tooltip", {
                                      attrs: {
                                        target: "btnZIn",
                                        title: "Zoom In",
                                        placement: "bottom"
                                      }
                                    }),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          id: "btnZout",
                                          variant: "primary",
                                          type: "button",
                                          disabled: _vm.showLoadingOverlay
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.moveCamera("zoom_out")
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "ZoomOutIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("b-tooltip", {
                                      attrs: {
                                        target: "btnZout",
                                        title: "Zoom Out",
                                        placement: "bottom"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }