var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
    ? _c("b-sidebar", {
        attrs: {
          id: "add-new-user-sidebar",
          visible: _vm.isFilmstripSidebarActive,
          "bg-variant": "white",
          "sidebar-class": "sidebar-lg",
          shadow: "",
          backdrop: "",
          "header-class": "d-block border-bottom-primary",
          "body-class": "mt-1 mb-1",
          right: ""
        },
        on: {
          change: function(val) {
            return _vm.$emit("update:is-filmstrip-sidebar-active", val)
          },
          shown: _vm.handleShowFilmstrip
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function(ref) {
                var hide = ref.hide
                return [
                  _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("b-col", [
                        _c("h5", { staticClass: "mb-0" }, [
                          _c("h4", [_vm._v("Images & Videos")])
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c("feather-icon", {
                            staticClass: "ml-1 cursor-pointer",
                            attrs: { icon: "XIcon", size: "16" },
                            on: { click: hide }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "d-none d-md-block mb-2 pr-1" },
                    [
                      _c("b-col", [
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c("flat-pickr", {
                              staticClass: "form-control",
                              attrs: {
                                placeholder: "Select Start Date / Time",
                                config: {
                                  enableTime: true,
                                  dateFormat: "Y-m-d H:i",
                                  altInput: true,
                                  altFormat: "F j, Y H:i"
                                }
                              },
                              on: {
                                "on-close": _vm.onCloseImageDateTime,
                                "on-open": function($event) {
                                  _vm.showcheckBtn = true
                                }
                              },
                              model: {
                                value: _vm.currentDateTime,
                                callback: function($$v) {
                                  _vm.currentDateTime = $$v
                                },
                                expression: "currentDateTime"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "input-group-btn" },
                              [
                                _vm.showcheckBtn
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "pl-1 pr-1",
                                        attrs: { variant: "primary" }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "default",
              fn: function() {
                return _vm._l(_vm.finalCameraMediaArr, function(theDateItem) {
                  return _c(
                    "div",
                    {
                      key:
                        "theDate-" +
                        theDateItem.theDate
                          .replace("T", "")
                          .replace(" ", "")
                          .replace("-", "")
                          .replace(":", ""),
                      staticClass: "ml-1 mr-1"
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-2 mb-1 border-bottom-primary" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            },
                            [
                              _c("h5", { staticClass: "mb-0" }, [
                                _c("h4", [
                                  _vm._v(_vm._s(theDateItem.theDateDisplay))
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "grid-view" },
                        _vm._l(theDateItem.mediaItems, function(mediaItem) {
                          return _c(
                            "b-card",
                            {
                              key:
                                "filmstrip-" +
                                mediaItem.time_camera
                                  .replace("T", "")
                                  .replace(" ", "")
                                  .replaceAll("-", "")
                                  .replaceAll(":", ""),
                              ref: mediaItem.time_camera
                                .replace("T", "")
                                .replace(" ", "")
                                .replaceAll("-", "")
                                .replaceAll(":", ""),
                              refInFor: true,
                              attrs: {
                                id: mediaItem.time_camera
                                  .replace("T", "")
                                  .replace(" ", "")
                                  .replaceAll("-", "")
                                  .replaceAll(":", ""),
                                "no-body": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "item-img text-center",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("itemclicked", mediaItem)
                                    }
                                  }
                                },
                                [
                                  _c("thumbs-lazy-image-component", {
                                    staticClass: "card-img-top",
                                    attrs: {
                                      src: mediaItem.access_url,
                                      "src-placeholder":
                                        "@/assets/images/noImage.png"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-card-footer",
                                { staticClass: "pt-1 pb-1 border-dark" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { cols: "8" } }, [
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Image Time: ")]
                                        ),
                                        _c("small", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDateTimeAMPM(
                                                mediaItem.time_camera.replace(
                                                  "T",
                                                  " "
                                                )
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      mediaItem.video_file !== undefined
                                        ? _c(
                                            "b-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "light-danger"
                                                  }
                                                },
                                                [_vm._v(" Video ")]
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "b-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "light-success"
                                                  }
                                                },
                                                [_vm._v(" Image ")]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                })
              },
              proxy: true
            }
          ],
          null,
          false,
          3850034252
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }