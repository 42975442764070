var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "DownloadIcon", size: "22" }
                  }),
                  _vm._v(
                    " Camera Media Downloads -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name)
                  ),
                  _c("br")
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              !_vm.cameraData.is_active
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-danger" }
                    },
                    [_vm._v(" Disabled ")]
                  )
                : _vm._e(),
              _c("br")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "ImageIcon", size: "18" }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Images")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("camera-edit-tab-image-downloads", {
                            ref: "CameraEditTabImageDownloads",
                            attrs: { "camera-data": _vm.cameraData }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }