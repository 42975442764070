var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
    ? _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-12 col-xl-6 d-flex align-items-center justify-content-start mb-1"
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")]),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.logs.fetchingList
                              },
                              on: { click: _vm.handleRefreshList }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-12 col-xl-6 d-flex align-items-left justify-content-start align-items-xl-right justify-content-xl-end mt-1 mb-1"
                        },
                        [
                          _vm.$can("create", "camerasmanagement") ||
                          _vm.$can("create", "cameras")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-end mb-1"
                                },
                                [
                                  _c("b-form-datepicker", {
                                    style: { minWidth: "15rem" },
                                    attrs: {
                                      max: _vm.maxDate,
                                      "date-format-options": {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        weekday: "short"
                                      },
                                      locale: "en"
                                    },
                                    model: {
                                      value: _vm.newZipDate,
                                      callback: function($$v) {
                                        _vm.newZipDate = $$v
                                      },
                                      expression: "newZipDate"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { variant: "primary" },
                                      on: { click: _vm.createNewDownload }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-nowrap" },
                                        [_vm._v("Create Download")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "refListTable",
                staticClass: "position-relative",
                attrs: {
                  items: _vm.fetchItems,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "log_id",
                  "sort-by": _vm.sortBy,
                  "show-empty": "",
                  "empty-text": "",
                  "sort-desc": _vm.isSortDirDesc,
                  busy: _vm.isBusy
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:busy": function($event) {
                    _vm.isBusy = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(start_date)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [
                                _vm._v(
                                  _vm._s(data.item.start_date.replace("T", " "))
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(end_date)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [
                                _vm._v(
                                  _vm._s(data.item.end_date.replace("T", " "))
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(actions)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleListItemClick(data.item)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "DownloadIcon" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-nowrap ml-1" },
                                    [_vm._v("Download")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  53196689
                )
              }),
              _c(
                "div",
                { staticClass: "mx-2 mb-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.dataMeta.from) +
                                " to " +
                                _vm._s(_vm.dataMeta.to) +
                                " of " +
                                _vm._s(_vm.dataMeta.of) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0",
                            attrs: {
                              "total-rows": _vm.totalItems,
                              "per-page": _vm.perPage,
                              "first-number": "",
                              "last-number": "",
                              "prev-class": "prev-item",
                              "next-class": "next-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }