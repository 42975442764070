var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-calendar overflow-hidden" },
    [
      _c(
        "b-media",
        {
          staticClass: "mb-2",
          scopedSlots: _vm._u([
            {
              key: "aside",
              fn: function() {
                return undefined
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "h4",
            { staticClass: "mb-1" },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "CameraIcon", size: "22" }
              }),
              _vm._v(
                " Media Calendar -- " +
                  _vm._s(_vm.cameraData.sitename) +
                  ", " +
                  _vm._s(_vm.cameraData.location_name)
              ),
              _c("br")
            ],
            1
          ),
          _c("small", [
            _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
          ]),
          !_vm.cameraData.is_active
            ? _c(
                "b-badge",
                {
                  staticClass: "text-capitalize ml-1",
                  attrs: { pill: "", variant: "light-danger" }
                },
                [_vm._v(" Disabled ")]
              )
            : _vm._e(),
          _c("br")
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _c("div", { staticClass: "col position-relative" }, [
                  _c(
                    "div",
                    { staticClass: "card shadow-none border-0 mb-0 rounded-0" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body pb-0" },
                        [
                          _c("full-calendar", {
                            ref: "refCalendar",
                            staticClass: "full-calendar",
                            attrs: { options: _vm.calendarOptions }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c("div", {
                  staticClass: "body-content-overlay",
                  class: { show: _vm.isCalendarOverlaySidebarActive },
                  on: {
                    click: function($event) {
                      _vm.isCalendarOverlaySidebarActive = false
                    }
                  }
                }),
                _c("calendar-event-handler", {
                  attrs: {
                    "camera-data": _vm.cameraData,
                    event: _vm.event,
                    "clear-event-data": _vm.clearEventData
                  },
                  on: { itemclicked: _vm.handleFilmstripClick },
                  model: {
                    value: _vm.isEventHandlerSidebarActive,
                    callback: function($$v) {
                      _vm.isEventHandlerSidebarActive = $$v
                    },
                    expression: "isEventHandlerSidebarActive"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }