var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("camera-edit-tab-timelapse-builder-add-new", {
        attrs: {
          "v-if":
            _vm.cameraData &&
            (_vm.$can("create", "cameras") ||
              _vm.$can("create", "camerasmanagement")),
          "is-add-new-timelapse-sidebar-active":
            _vm.isAddNewTimelapseSidebarActive,
          cameraid: _vm.cameraData.camera_id
        },
        on: {
          "update:isAddNewTimelapseSidebarActive": function($event) {
            _vm.isAddNewTimelapseSidebarActive = $event
          },
          "update:is-add-new-timelapse-sidebar-active": function($event) {
            _vm.isAddNewTimelapseSidebarActive = $event
          },
          "update:cameraid": function($event) {
            return _vm.$set(_vm.cameraData, "camera_id", $event)
          }
        }
      }),
      _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
        ? _c(
            "b-media",
            { staticClass: "mb-2" },
            [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "CameraIcon", size: "22" }
                  }),
                  _vm._v(
                    " Timelapse Builder -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name)
                  ),
                  _c("br")
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              !_vm.cameraData.is_active
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-danger" }
                    },
                    [_vm._v(" Disabled ")]
                  )
                : _vm._e(),
              _c("br"),
              _c("small", [
                _vm._v("Time Zone: " + _vm._s(_vm.cameraData.time_zone))
              ]),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
        ? _c(
            "div",
            {
              staticClass: "ecommerce-application",
              staticStyle: { height: "inherit" }
            },
            [
              _vm.$can("read", "cameras") ||
              _vm.$can("read", "camerasmanagement")
                ? _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-push-12 col-lg-6 d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { md: "6" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.filters.perPage,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "perPage", $$v)
                              },
                              expression: "filters.perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")]),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.cameraconsole
                                  .fetchingTimelapseList
                              },
                              on: { click: _vm.reFetchViewItems }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.isAddNewTimelapseSidebarActive = true
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "PlusIcon" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-push-12 col-lg-3 d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { md: "3" }
                        },
                        [
                          _c("label", [_vm._v("From ")]),
                          _c(
                            "div",
                            { staticClass: "input-group" },
                            [
                              _c("flat-pickr", {
                                staticClass: "form-control ml-1",
                                attrs: {
                                  config: {
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    altInput: true,
                                    altFormat: "F j, Y H:i"
                                  }
                                },
                                on: {
                                  "on-close": function($event) {
                                    _vm.startTimeCheckBtn = false
                                  },
                                  "on-open": function($event) {
                                    _vm.startTimeCheckBtn = true
                                  }
                                },
                                model: {
                                  value: _vm.filters.startTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "startTime", $$v)
                                  },
                                  expression: "filters.startTime"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "input-group-btn" },
                                [
                                  _vm.startTimeCheckBtn
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "pl-1 pr-1",
                                          attrs: { variant: "primary" }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { icon: "CheckIcon" }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "col-push-12 col-lg-3 d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { md: "3" }
                        },
                        [
                          _c("label", [_vm._v("To ")]),
                          _c(
                            "div",
                            { staticClass: "input-group" },
                            [
                              _c("flat-pickr", {
                                staticClass: "form-control ml-1",
                                attrs: {
                                  config: {
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    altInput: true,
                                    altFormat: "F j, Y H:i"
                                  }
                                },
                                on: {
                                  "on-close": function($event) {
                                    _vm.endTimeCheckBtn = false
                                  },
                                  "on-open": function($event) {
                                    _vm.endTimeCheckBtn = true
                                  }
                                },
                                model: {
                                  value: _vm.filters.endTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "endTime", $$v)
                                  },
                                  expression: "filters.endTime"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "input-group-btn" },
                                [
                                  _vm.endTimeCheckBtn
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "pl-1 pr-1",
                                          attrs: { variant: "primary" }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { icon: "CheckIcon" }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can("read", "cameras") ||
              _vm.$can("read", "camerasmanagement")
                ? _c(
                    "section",
                    { staticClass: "grid-view" },
                    _vm._l(_vm.timelapseItems, function(item) {
                      return _c(
                        "b-card",
                        {
                          key: item.theIndex + "_timelapse",
                          attrs: {
                            id: item.theIndex + "_timelapse",
                            "no-body": "",
                            role: "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.playVideoFile(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "item-img text-center" },
                            [
                              item.access_url !== undefined &&
                              item.access_url !== ""
                                ? _c("thumbs-lazy-image-component", {
                                    staticClass: "card-img-top",
                                    attrs: { src: item.access_url }
                                  })
                                : _c("b-img", {
                                    staticClass: "card-img-top",
                                    attrs: {
                                      alt: "" + item.start_dt,
                                      fluid: "",
                                      src: require("@/assets/images/noImageLg.png")
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "b-card-footer",
                            { staticClass: "pt-1 pb-1 border-dark" },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Start Date: ")
                              ]),
                              _c("small", [
                                _vm._v(
                                  _vm._s(_vm.formatDateTimeAMPM(item.start_dt))
                                )
                              ]),
                              _c("br"),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("End Date: ")
                              ]),
                              _c("small", [
                                _vm._v(
                                  _vm._s(_vm.formatDateTimeAMPM(item.end_dt))
                                )
                              ]),
                              _c("br"),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Time of Day: ")
                              ]),
                              _c("small", [_vm._v(_vm._s(item.time_of_day))]),
                              _c("br"),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Speed: ")
                              ]),
                              _c("small", [_vm._v(_vm._s(item.speed) + " fps")])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.$can("read", "cameras") ||
              _vm.$can("read", "camerasmanagement")
                ? _c(
                    "section",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.totalTimelapseItems,
                                  "per-page": _vm.filters.perPage,
                                  "first-number": "",
                                  align: "center",
                                  "last-number": "",
                                  "prev-class": "prev-item",
                                  "next-class": "next-item"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prev-text",
                                      fn: function() {
                                        return [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronLeftIcon",
                                              size: "18"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "next-text",
                                      fn: function() {
                                        return [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronRightIcon",
                                              size: "18"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1308952388
                                ),
                                model: {
                                  value: _vm.filters.page,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "page", $$v)
                                  },
                                  expression: "filters.page"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
        ? _c(
            "b-modal",
            {
              ref: "vmodal",
              attrs: {
                id: "vmodal",
                "ok-only": "",
                "ok-title": "Close",
                centered: "",
                size: "lg",
                title: _vm.videoTitle
              },
              on: {
                close: function($event) {
                  _vm.showVideoModal = false
                }
              }
            },
            [
              _c("vue-plyr", { attrs: { options: _vm.options } }, [
                _c(
                  "video",
                  {
                    attrs: {
                      controls: "",
                      crossorigin: "",
                      playsinline: "",
                      "data-poster": _vm.videoPoster
                    }
                  },
                  [
                    _c("source", {
                      attrs: {
                        size: "1080",
                        src: _vm.videoSrc,
                        type: "video/mp4"
                      }
                    })
                  ]
                )
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mt-1",
                  attrs: {
                    id: "button-video-download",
                    variant: "primary",
                    type: "button"
                  },
                  on: { click: _vm.downloadVideo }
                },
                [
                  _c("feather-icon", { attrs: { icon: "DownloadIcon" } }),
                  _vm._v(" Download Video File ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }